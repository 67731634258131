/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable no-param-reassign */

/* eslint-disable eqeqeq */

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable prettier/prettier */

/* eslint-disable no-multi-spaces */

/* eslint-disable indent */

/* eslint-disable @typescript-eslint/indent */

/* eslint-disable operator-linebreak */
import type { ICartState, ItemInterface, IShipping, IShippingRules } from './types';
import { getProperShippingsList } from './utils';

class CartActions {
  addToCart = (state: ICartState, { payload }: { payload: ItemInterface }) => {
    const item = { ...payload };

    const productIndex = state.items.findIndex(
      ({ product_id, itemConfig }) =>
        item.product_id === product_id && item.itemConfig === itemConfig
    );

    const { quantity, sale, regularPrice, price } = item;
    const items =      productIndex === -1
        ? [...state.items, item]
        : [...state.items].map((item, index) => {
          if (index === productIndex) {
              const additionalItemValue = sale
                ? quantity * price
              : quantity * regularPrice;
            return {
                ...item,
                quantity: quantity + item.quantity,
                value: item.value + additionalItemValue,
              regularValue: item.regularValue + quantity * regularPrice,
            };
            }
            return item;
        });

    const val = items.reduce((acc, cur) => acc + cur.value, 0);
    const qty = items.reduce((acc, cur) => acc + cur.quantity, 0);

    return {
      ...state,
      items,
      sum: { val, qty },
      shippings: getProperShippingsList(state.shippingRules, val),
    };
  };

  removeFromCart = (
    state: ICartState,
    { payload }: { payload: ItemInterface },
  ) => {
    const item = { ...payload };
    const { id } = item;
    const newItems = [...state.items].filter(
      (el) => (el.id !==  id),
    );

    const val = newItems.reduce((acc, cur) => acc + cur.value, 0);
    const qty = newItems.reduce((acc, cur) => acc + cur.quantity, 0);

    return {
      ...state,
      items: newItems,
      sum: { val, qty },
      shippings: getProperShippingsList(state.shippingRules, val),
    };
  };

  changeProductQty = (
    state: ICartState,
    { payload }: { payload: ItemInterface },
  ) => {
    const item = { ...payload };
    const { quantity, id } = item;
    console.log('change', id);

    if (quantity <= 0) return { ...state };

    const newItems = [...state.items].map((el) => {
      if (el.id == id) {
        console.log('el', el);
        el.quantity = quantity;
        el.regularValue = quantity * el.price;
        if (el.sale) {
          el.value = quantity * el.price;
        } else {
          el.value = quantity * el.price;
        }
      }
      return el;
    });

    const val = newItems.reduce((acc, cur) => acc + cur.value, 0);
    const qty = newItems.reduce((acc, cur) => acc + cur.quantity, 0);

    return {
      ...state,
      items: newItems,
      sum: { val, qty },
      shippings: getProperShippingsList(state.shippingRules, val),
    };
  };

  setShippings = (
    state: ICartState,
    { payload }: { payload: IShippingRules },
  ) => {
    console.log('setShippingPayload: ', payload);
    const action = { ...payload };

    const val = state.items.reduce((acc, cur) => acc + cur.value, 0);

    return {
      ...state,
      shippingRules: action,
      shippings: getProperShippingsList(action, val),
    };
  };

  setShipping = (state: ICartState, { payload }: { payload: IShipping }) => ({
    ...state,
    shipping: { ...payload },
  });

  setCoupon = (state: ICartState, { payload }: { payload: string }) => ({
    ...state,
    coupon: payload,
  });

  setOrderFromApi = (
    state: ICartState,
    { payload }: { payload: Record<string, any> },
  ) => {
    console.log(`setOrderFromApiPayload: ${payload}`);

    return {
      ...state,
      imBusy: false,
      error: null,
      orderInApi: payload,
    };
  };

  onOrderApiError = (state: ICartState, { payload }: { payload: any }) => {
    const action = { ...payload };

    return {
      ...state,
      imBusy: false,
      error: action,
    };
  };

  loadCart = (
    state: ICartState,
    { payload: { cart } }: { payload: { cart: ICartState } },
  ) => {
    const { items } = cart;
    const val = items.reduce((acc, cur) => acc + cur.value, 0);
    const qty = items.reduce((acc, cur) => acc + cur.quantity, 0);
    return {
      ...state,
      items,
      sum: { val, qty },
    };
  };

  resetCart = (state: ICartState) => ({
    ...state,
    imBusy: false,
    items: [],
    shippings: getProperShippingsList(state.shippingRules, 0),
    orderInApi: {},
    // shipping: {},
    coupon: '',
    sum: { val: 0, qty: 0 },
  });

  changePaymentStatus = (
    state: ICartState,
    { payload }: { payload: Record<string, string> },
  ) => ({
    ...state,
    payment: payload,
  });
}

const actions = new CartActions();
export default actions;
