import { IAuthState } from './types';

const initialState: IAuthState = {
  loggedIn: false,
  id: 0,
  email: '',
  nick: '',
  first_name: '',
  last_name: '',
  address_1: '',
  address_2: '',
  city: '',
  postcode: '',
  country: 'Polska',
  phone: '',
  note: ''
};

export default initialState;
