/* eslint-disable import/no-named-as-default */

/* eslint-disable import/no-named-as-default-member */

/* eslint-disable prettier/prettier */

/* eslint-disable @typescript-eslint/indent */
import actions from './actions';
import initialState from './state';
import { AuthActionType, IAuthState } from './types';

function authReducer(state = initialState, action: AuthActionType): IAuthState {
  switch (action.type) {
  case 'loadAuth':
    return actions.loadAuth(state, action);
  case 'logIn':
    return actions.logIn(state, action);
  case 'logOut':
    return actions.logOut(state, action);
  case 'setUserDetails':
    return actions.setUserDetails(state, action);
  default:
    throw new Error('Wrong action type in app reducer');
  }
}
export default authReducer;
