import React from 'react';

import initialState from './state';
import type { ICustomerContext } from './types';

const CartCtx = React.createContext<ICustomerContext>({
  state: { ...initialState },
  dispatch: () => null
});

export default CartCtx;
