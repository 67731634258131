/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useReducer, useEffect, useLayoutEffect } from 'react';

import CartCtx from './ctx';
import reducer from './reducer';
import initialState from './state';
import type { CartProviderProps } from './types';

const CartProvider: React.FC<CartProviderProps> = ({
  children,
  onLoad = () => false,
  onStateChange = () => false
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useLayoutEffect(() => {
    onLoad(dispatch);
    const cart = localStorage.getItem('CartCtx');
    if (cart) {
      dispatch({
        type: 'loadCart',
        payload: {
          cart: JSON.parse(cart)
        }
      });
    }
  }, []);

  useEffect(() => {
    console.log('CartCtx state ', state);
    onStateChange(state);
    localStorage.setItem('CartCtx', JSON.stringify(state));
  }, [state]);

  return (
    <CartCtx.Provider
      value={{
        state,
        dispatch
      }}
    >
      {'>'}
      {children}
    </CartCtx.Provider>
  );
};

export { CartProvider, CartCtx };
