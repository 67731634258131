/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomerProvider,
  CartProvider,
  AppProvider,
  WindowProvider,
  WindowCtx,
  useActions,
  AppCtx,
  AuthCtx,
  AuthProvider
} from '@components/contexted';
import { Cart } from 'components.refactored/layout/Header/styles';
import React, { useCallback } from 'react';

import Loader from './InitLoading';

interface ProviderProps {
  children: React.ReactNode;
}

const onAppInit = (dispatch) => {};

const onInitSearchData = (appLoaded) => (dispatch) => {
  appLoaded();
};

const OuterProviders: React.FC<ProviderProps> = ({ children }) => (
  <WindowProvider>
    <AppProvider onLoad={onAppInit}>{children}</AppProvider>
  </WindowProvider>
);

const InnerProviders: React.FC<ProviderProps> = ({ children }) => {
  const { loaded } = useActions(AppCtx, 'loaded');
  const onLoadSearchData = useCallback(onInitSearchData(loaded), []);

  return (
    <CustomerProvider onLoad={onLoadSearchData}>
      <AuthProvider>
        <CartProvider>
          <Loader />
          {children}
        </CartProvider>
      </AuthProvider>
    </CustomerProvider>
  );
};

export default ({ element }: { element: React.ReactNode }) => (
  <OuterProviders>
    <InnerProviders>{element}</InnerProviders>
  </OuterProviders>
);

export { WindowCtx };
