/* eslint-disable react/prop-types */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { WPApi } from '@api';
import Cookies from 'js-cookie';
import React, { useReducer, useEffect, useLayoutEffect, useState } from 'react';

import AuthCtx from './ctx';
import reducer from './reducer';
import initialState from './state';

const AuthProvider = ({ children, onLoad = () => false }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [firstRender, setFirstRender] = useState(true);

  useLayoutEffect(() => {
    onLoad(dispatch);
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');

    WPApi.validateToken(token)
      .then(() => {
        const auth = localStorage.getItem('AuthCtx');
        if (auth) {
          dispatch({
            type: 'loadAuth',
            payload: {
              auth: JSON.parse(auth)
            }
          });
        }
        setFirstRender(false);
      })
      .catch(() => {
        dispatch({
          type: 'logOut'
        });
        setFirstRender(false);
      });
  }, []);

  useEffect(() => {
    if (!firstRender) {
      console.log('AuthCtx state', state);
      localStorage.setItem('AuthCtx', JSON.stringify(state));
    }
  }, [state, firstRender]);

  return (
    <AuthCtx.Provider
      value={{
        state: { ...state },
        dispatch
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

export { AuthProvider, AuthCtx };
