/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable @typescript-eslint/restrict-plus-operands */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import WOOCOMMERCE from '../env/WOOCOMMERCE';
import { request } from './request';

const { KEY, SECRET } = WOOCOMMERCE.APIAUTH;

class WPAPI {
  constructor() {
    this.route = '/wp-json/wc/v3';
  }

  getDiscount(items, coupon) {
    const url = 'wp-json/v1/coupons/check-basket-discount';
    console.log('iii', items);
    const config = {
      method: 'POST',
      data: {
        items,
        coupon
      }
    };
    return request(url, config);
  }

  postCreateOrder(customer, cart, shipping, coupon = {}, token) {
    const url = `${this.route}/orders`;
    const config = {
      method: 'POST',
      withCredentials: true,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        billing: customer,
        shipping: customer,
        customer_note: customer.note,
        line_items: cart,
        shipping_lines: [shipping],
        ...coupon
      }
    };
    return request(url, config);
  }

  changeOrder(id, token, data = { status_order: 'completed' }) {
    const url = `wp-json/wp/v2/orders/status/?id=${id}`;
    const config = {
      method: 'PUT',
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
      data
    };
    return request(url, config);
  }

  searchByPaymentId(orderId, token) {
    const url = `wp-json/wp/v2/orders/searchById/?search=${orderId}`;
    const config = {
      method: 'GET',
      withCredentials: true,

      headers: { Authorization: `Bearer ${token}` }
    };
    return request(url, config);
  }

  search(phrase = '') {
    const urlProducts = `${this.route}/products?search=${phrase}`;
    const configProducts = {
      method: 'GET',
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET
      }
    };
    const orders = () => request(urlProducts, configProducts);

    const urlPosts = `/wp-json/wp/v2/posts?search=${phrase}`;
    const configPosts = {
      method: 'GET'
    };
    const posts = () => request(urlPosts, configPosts);

    const urlPages = `/wp-json/wp/v2/pages?search=${phrase}`;
    const configPages = {
      method: 'GET'
    };
    const pages = () => request(urlPages, configPages);

    return Promise.all([orders(), posts(), pages()]);
  }

  sendMessage(name: string, surname: string, email: string, message: string) {
    const url = '/wp-json/contact-form-7/v1/contact-forms/297/feedback';
    const data = new FormData();
    data.append('your-name', name);
    data.append('surname', surname);
    data.append('email', email);
    data.append('message', message);

    const config = {
      method: 'POST',
      multipart: true,
      data
    };

    return request(url, config);
  }

  getDeliveryOptions() {
    // POST /wp-json/wc/v3/shipping/zones
    // POST /wp-json/wc/v3/shipping/zones/<id>/methods
    const url = '/wp-json/wc/v3/shipping/zones';
    const config = {
      method: 'GET',
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET
      }
    };
    return request(url, config).then((data) => {
      const [selected] = data.filter((el) => el.name === 'Remote');
      const url = `/wp-json/wc/v3/shipping/zones/${selected.id}/methods`;
      const config = {
        method: 'GET',
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET
        }
      };
      return request(url, config);
    });
  }

  /// ////VALIDATION/////////
  registerAndGetToken(customer) {
    const url = 'wp-json/wp/v2/users/register';
    const newUsername = customer.first_name + customer.last_name;
    const newPassword = Math.random().toString(36).substring(7);
    const nameHash = newUsername + Math.random().toString(36).substring(7);
    const emailHash = customer.email + Math.random().toString(36).substr(2, 5);
    console.log('e', emailHash);
    console.log('p', newPassword);
    console.log('n', nameHash);
    const config = {
      method: 'POST',
      data: {
        email: emailHash,
        password: newPassword,
        name: nameHash,
        surname: 'teeest'
      }
    };
    return request(url, config).then(() => {
      const url = '/wp-json/jwt-auth/v1/token';
      const config = {
        method: 'POST',
        data: {
          username: emailHash,
          password: newPassword
        }
      };
      return request(url, config);
    });
  }

  deleteToken() {
    const token = localStorage.getItem('token');
    const url = '/wp-json/jwt-auth/v1/token/cancellation';
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` }
    };
    return request(url, config);
  }

  // auth flow
  registerNewUser(user) {
    const url = 'wp-json/wp/v2/users/register';
    const { email, password, name, surname } = user;
    const config = {
      method: 'POST',
      data: {
        email,
        password,
        name,
        surname
      }
    };
    return request(url, config);
  }

  loginUser(user) {
    const url = '/wp-json/jwt-auth/v1/token';
    const { email, password } = user;
    const config = {
      method: 'POST',
      data: {
        username: email,
        password
      }
    };
    return request(url, config);
  }

  getUserInfo(token, id) {
    const url = '/wp-json/wp/v2/users/update';
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
      data: {
        id
      }
    };
    return request(url, config);
  }

  changeUserPasswordFromRemind(user, token) {
    const url = '/wp-json/wp/v2/users/update_user_password';
    const { email, password } = user;
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
      data: {
        email,
        password
      }
    };
    return request(url, config);
  }

  validateToken(token) {
    const url = '/wp-json/jwt-auth/v1/token/validate';
    const config = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` }
    };
    return request(url, config);
  }

  requestPasswordChangeLink(user) {
    const url = '/wp-json/wp/v2/users/remind_password';
    const { email, password } = user;
    const config = {
      method: 'POST',
      data: {
        email,
        password
      }
    };
    return request(url, config);
  }

  addNewConfiguration(data: {
    user_id: number;
    uuid: string;
    product_slug: string;
    product_name: string;
    product_category: string;
    configuration: string;
  }) {
    const url = '/wp-json/wp/v2/product-configuration/add-new-configuration';
    const config = {
      method: 'POST',
      data
    };
    return request(url, config);
  }

  deleteConfiguration(data: { uuid: string; user_id: number }) {
    const url = '/wp-json/wp/v2/product-configuration/delete-configuration';
    const config = {
      method: 'POST',
      data
    };
    return request(url, config);
  }

  getUserConfigurations(user_id: number) {
    const url = '/wp-json/wp/v2/product-configuration/get-user-configurations';
    const config = {
      method: 'POST',
      data: {
        user_id
      }
    };
    return request(url, config);
  }
}

const WPApi = new WPAPI();

export default WPApi;
