// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog/category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-ecommerce-cart-tsx": () => import("./../../../src/templates/ecommerce/cart.tsx" /* webpackChunkName: "component---src-templates-ecommerce-cart-tsx" */),
  "component---src-templates-ecommerce-category-tsx": () => import("./../../../src/templates/ecommerce/category.tsx" /* webpackChunkName: "component---src-templates-ecommerce-category-tsx" */),
  "component---src-templates-ecommerce-product-tsx": () => import("./../../../src/templates/ecommerce/product.tsx" /* webpackChunkName: "component---src-templates-ecommerce-product-tsx" */),
  "component---src-templates-ecommerce-purchase-summary-tsx": () => import("./../../../src/templates/ecommerce/purchase-summary.tsx" /* webpackChunkName: "component---src-templates-ecommerce-purchase-summary-tsx" */),
  "component---src-templates-ecommerce-saved-items-tsx": () => import("./../../../src/templates/ecommerce/savedItems.tsx" /* webpackChunkName: "component---src-templates-ecommerce-saved-items-tsx" */),
  "component---src-templates-ecommerce-summary-tsx": () => import("./../../../src/templates/ecommerce/summary.tsx" /* webpackChunkName: "component---src-templates-ecommerce-summary-tsx" */),
  "component---src-templates-standard-about-tsx": () => import("./../../../src/templates/standard/about.tsx" /* webpackChunkName: "component---src-templates-standard-about-tsx" */),
  "component---src-templates-standard-app-tsx": () => import("./../../../src/templates/standard/app.tsx" /* webpackChunkName: "component---src-templates-standard-app-tsx" */),
  "component---src-templates-standard-contact-tsx": () => import("./../../../src/templates/standard/contact.tsx" /* webpackChunkName: "component---src-templates-standard-contact-tsx" */),
  "component---src-templates-standard-forgot-password-tsx": () => import("./../../../src/templates/standard/forgot-password.tsx" /* webpackChunkName: "component---src-templates-standard-forgot-password-tsx" */),
  "component---src-templates-standard-home-tsx": () => import("./../../../src/templates/standard/home.tsx" /* webpackChunkName: "component---src-templates-standard-home-tsx" */),
  "component---src-templates-standard-knowledge-tsx": () => import("./../../../src/templates/standard/knowledge.tsx" /* webpackChunkName: "component---src-templates-standard-knowledge-tsx" */),
  "component---src-templates-standard-login-tsx": () => import("./../../../src/templates/standard/login.tsx" /* webpackChunkName: "component---src-templates-standard-login-tsx" */),
  "component---src-templates-standard-offer-for-companies-tsx": () => import("./../../../src/templates/standard/offer-for-companies.tsx" /* webpackChunkName: "component---src-templates-standard-offer-for-companies-tsx" */),
  "component---src-templates-standard-registration-tsx": () => import("./../../../src/templates/standard/registration.tsx" /* webpackChunkName: "component---src-templates-standard-registration-tsx" */),
  "component---src-templates-standard-reset-password-tsx": () => import("./../../../src/templates/standard/reset-password.tsx" /* webpackChunkName: "component---src-templates-standard-reset-password-tsx" */),
  "component---src-templates-standard-uses-tsx": () => import("./../../../src/templates/standard/uses.tsx" /* webpackChunkName: "component---src-templates-standard-uses-tsx" */)
}

