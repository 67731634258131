import React from 'react';

import initialState from './state';
import { IAppContext } from './types';

const AppCtx = React.createContext<IAppContext>({
  state: { ...initialState },
  dispatch: () => null
});

export default AppCtx;
