class AppActions {
  loaded = () => ({
    imBusy: false
  });

  loading = () => ({
    imBusy: true
  });
}

const actions = new AppActions();
export default actions;
