/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable prefer-promise-reject-errors */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios from 'axios';
import querystring from 'query-string';

import WP from '../env/WP';

const DEBUG = false;

const instance = axios.create({
  baseURL: WP.URL,
  timeout: 1000 * 60,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

const logger = (data: Record<string, any>, url: string) => {
  DEBUG &&
    console.log(
      url,
      `\n\t status: ${data.status}`,
      '\n\t payload: ',
      data.data
    );
  return data.data;
};

export const request = (_url: string, _config: Record<string, any> = {}) => {
  DEBUG && console.log(_url, 'config', _config);
  const req: Record<string, any> = {
    url: _url,
    ..._config
  };
  if (!req.headers) {
    req.headers = {};
  }
  if (_config.multipart) {
    req.headers['content-type'] = 'multipart/form-data';
  }

  if (_config.query && Object.keys(_config.query).length !== 0) {
    req.url += `?${querystring.stringify(_config.query, {
      arrayFormat: 'bracket'
    })}`;
  }

  return instance
    .request(req)
    .then((data) => logger(data, _url))
    .catch((err) => {
      const { data, status } = err.response;
      return Promise.reject({ data, status });
    });
};
