/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable implicit-arrow-linebreak */
import { useContext, Context } from 'react';

import { AppProvider, AppCtx } from './App/Provider';
import { CartProvider, CartCtx } from './Cart/Provider';
import { CustomerProvider, CustomerCtx } from './Customer/Provider';
import { LangProvider, LangCtx } from './Lang/Provider';
import { WindowProvider, WindowCtx } from './Window/Provider';
import { AuthProvider, AuthCtx } from './Auth/provider';
import { WithState, WithDispatch, ElasticObject } from './index.d';

const useActions = <T extends WithDispatch>(
  ctx: Context<T>,
  selected: string | string[]
) => {
  const { dispatch } = useContext<T>(ctx);

  if (Array.isArray(selected)) {
    return selected.reduce(
      (actions: ElasticObject, name: string) => ({
        ...actions,
        [name]: (payload: any) =>
          dispatch({
            type: name,
            payload
          })
      }),
      {}
    );
  }
  return {
    [selected]: (payload: any) =>
      dispatch({
        type: selected,
        payload
      })
  };
};

const useContextState = <T extends WithState>(
  ctx: Context<T>,
  selected: string | string[] = '*'
) => {
  const { state } = useContext<T>(ctx);
  if (Array.isArray(selected)) {
    return selected.reduce(
      (states: ElasticObject, name: string) => ({
        ...states,
        [name]: state[name]
      }),
      {}
    );
  }
  return selected === '*' ?
    state :
    {
      [selected]: state[selected]
    };
};

export {
  useActions,
  useContextState,
  CartProvider,
  CartCtx,
  CustomerProvider,
  CustomerCtx,
  AppProvider,
  AppCtx,
  WindowProvider,
  WindowCtx,
  LangProvider,
  LangCtx,
  AuthProvider,
  AuthCtx
};
