/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */
import Cookies from 'js-cookie';

import type {
  IAuthState,
  ILoginPayload,
  LoadAuthPayloadType,
  IGeInfoPayload
} from './types';

class AuthActions {
  loadAuth = (
    state: IAuthState,
    { payload }: { payload: LoadAuthPayloadType }
  ) => {
    const { auth } = payload;

    return {
      ...state,
      ...auth
    };
  };

  logIn = (state: IAuthState, { payload }: { payload: ILoginPayload }) => {
    console.log('ppp', payload);
    const { token, email, firstName, lastName, nicename, id } = payload;
    Cookies.set('token', token, { expires: 2 });
    return {
      ...state,
      loggedIn: true,
      id,
      email,
      nick: nicename,
      first_name: firstName,
      last_name: lastName
    };
  };

  setUserDetails = (
    state: IAuthState,
    { payload }: { payload: IGeInfoPayload }
  ) => {
    const { billing } = payload;

    return {
      ...state,
      first_name: billing.first_name,
      last_name: billing.last_name,
      address_1: billing.address_1,
      address_2: billing.address_2,
      city: billing.city,
      postcode: billing.postcode,
      phone: billing.phone
    };
  };

  logOut = (state: IAuthState) => {
    Cookies.remove('token');
    return {
      ...state,
      loggedIn: false,
      id: 0,
      email: '',
      nick: '',
      first_name: '',
      last_name: '',
      address_1: '',
      address_2: '',
      city: '',
      postcode: '',
      country: 'Polska',
      phone: '',
      note: ''
    };
  };
}

const actions = new AuthActions();
export default actions;
