/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/indent */
import actions from './actions';
import initialState from './state';
import type { IWindowState, WindowActionType } from './types';

function windowReducer(
  state = initialState,
  action: WindowActionType,
): IWindowState {
  switch (action.type) {
  case 'changeWindow':
    return actions.changeWindow(state, action);
  default:
    throw new Error('Wrong action type in window reducer');
  }
}

export default windowReducer;
