import axios from 'axios';

// import API_KEY from '../env/MAILERLITE';

// const localhostGroupId = 107056684;

const ebookGroup = 104465387;
// const contactGroup =

const instance = axios.create({
  baseURL: 'https://api.mailerlite.com/api/v2/',
  headers: {
    'Content-Type': 'application/json',
    'x-mailerlite-apikey': 'b2336c60755432836d416c7394db6b99',
    'Access-Control-Allow-Headers':
      'x-mailerlite-apikey,Origin, Content-Type, X-Auth-Token'
  }
});

class MAILERLITE {
  addEbookSubscriber(email: string, name: string) {
    instance
      .post(`groups/${ebookGroup}/subscribers`, {
        email,
        name
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // addContactSubscriber
}

const MailerLiteApi = new MAILERLITE();

export default MailerLiteApi;
